import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode, Pagination } from "swiper";

import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

const Progress = () => {
    const [modal, setModal] = useState(null); // selected month

    // Define all months and their associated data in a single data structure
    const monthData = {
        "February 2025": {
            thumbnail: "/progress/feb-2025-1.jpeg",
            images: [
                "/progress/feb-2025-1.jpeg",
                "/progress/feb-2025-2.jpeg",
                "/progress/feb-2025-3.jpeg"
            ]
        },
        "January 2025": {
            thumbnail: "/progress/jan-2025-1.jpeg",
            images: [
                "/progress/jan-2025-1.jpeg",
                "/progress/jan-2025-2.jpeg",
                "/progress/jan-2025-3.jpeg"
            ]
        },
        "December 2024": {
            thumbnail: "/progress/dec-2024-1.jpeg",
            images: [
                "/progress/dec-2024-1.jpeg",
                "/progress/dec-2024-2.jpeg",
                "/progress/dec-2024-3.jpeg"
            ]
        },
        "November 2024": {
            thumbnail: "/progress/nov-2024-1.jpeg",
            images: [
                "/progress/nov-2024-1.jpeg",
                "/progress/nov-2024-2.jpg",
                "/progress/nov-2024-3.jpeg"
            ]
        },
        "October 2024": {
            thumbnail: "/progress/oct-2024-1.jpeg",
            images: [
                "/progress/oct-2024-1.jpeg",
                "/progress/oct-2024-2.jpg",
                "/progress/oct-2024-3.jpg"
            ]
        },
        "September 2024": {
            thumbnail: "/progress/sep-2024-1.jpeg",
            images: [
                "/progress/sep-2024-1.jpeg",
                "/progress/sep-2024-2.jpeg",
                "/progress/sep-2024-3.jpg"
            ]
        },"August 2024": {
            thumbnail: "/progress/aug-2024-1.jpg",
            images: [
                "/progress/aug-2024-1.jpg",
                "/progress/aug-2024-2.jpg",
                "/progress/aug-2024-3.jpg"
            ]
        },
        "July 2024": {
            thumbnail: "/progress/july-2024-1.jpg",
            images: [
                "/progress/july-2024-1.jpg",
                "/progress/july-2024-2.jpg",
                "/progress/july-2024-3.png"
            ]
        },
        "June 2024": {
            thumbnail: "/progress/june-2024-1.jpg",
            images: [
                "/progress/june-2024-1.jpg",
                "/progress/june-2024-2.jpg",
                "/progress/june-2024-3.jpg"
            ]
        },
        "May 2024": {
            thumbnail: "/progress/may-2024-1.jpg",
            images: [
                "/progress/may-2024-1.jpg",
                "/progress/may-2024-2.jpg",
                "/progress/may-2024-3.jpeg"
            ]
        },
        "April 2024": {
            thumbnail: "/progress/apr-2024-1.jpg",
            images: [
                "/progress/apr-2024-1.jpg",
                "/progress/apr-2024-2.jpg",
                "/progress/apr-2024-3.jpg"
            ]
        },
        "March 2024": {
            thumbnail: "/progress/mar-2024-1.jpg",
            images: [
                "/progress/mar-2024-1.jpg",
                "/progress/mar-2024-2.jpg",
                "/progress/mar-2024-3.jpg"
            ]
        },
        "February 2024": {
            thumbnail: "/progress/feb-2024-1.jpeg",
            images: [
                "/progress/feb-2024-1.jpeg",
                "/progress/feb-2024-2.jpeg",
                "/progress/feb-2024-3.jpg"
            ]
        },
        "January 2024": {
            thumbnail: "/progress/jan-2024-1.jpg",
            images: [
                "/progress/jan-2024-1.jpg",
                "/progress/jan-2024-2.jpg",
                "/progress/jan-2024-3.jpg"
            ]
        },
        "December 2023": {
            thumbnail: "/progress/dec-2023-1.jpeg",
            images: [
                "/progress/dec-2023-1.jpeg",
                "/progress/dec-2023-2.jpeg",
                "/progress/dec-2023-3.jpeg"
            ]
        },
        "November 2023": {
            thumbnail: "/progress/nov-2023-1.jpeg",
            images: [
                "/progress/nov-2023-1.jpeg",
                "/progress/nov-2023-2.jpeg",
                "/progress/nov-2023-3.jpeg"
            ]
        },
        "October 2023": {
            thumbnail: "/progress/oct-2023-1.jpg",
            images: [
                "/progress/oct-2023-1.jpg",
                "/progress/oct-2023-2.jpg",
                "/progress/oct-2023-3.jpg"
            ]
        },
        "September 2023": {
            thumbnail: "/progress/sep-2023-1.png",
            images: [
                "/progress/sep-2023-1.png",
                "/progress/sep-2023-2.png",
                "/progress/sep-2023-3.png"
            ]
        },
        "August 2023": {
            thumbnail: "/progress/aug-2023-1.jpg",
            images: [
                "/progress/aug-2023-1.jpg",
                "/progress/aug-2023-2.jpeg",
                "/progress/aug-2023-3.jpeg"
            ]
        },
        "July 2023": {
            thumbnail: "/progress/jul-2023-1.jpg",
            images: [
                "/progress/jul-2023-1.jpg",
                "/progress/jul-2023-2.png",
                "/progress/jul-2023-3.jpg"
            ]
        },
        "June 2023": {
            thumbnail: "/progress/jun-2023-1.jpg",
            images: [
                "/progress/jun-2023-1.jpg",
                "/progress/jun-2023-2.jpg",
                "/progress/jun-2023-3.jpg"
            ]
        },
        "May 2023": {
            thumbnail: "/progress/may-2023-1.jpg",
            images: [
                "/progress/may-2023-1.jpg",
                "/progress/may-2023-2.jpg",
                "/progress/may-2023-3.jpg"
            ]
        },
        "April 2023": {
            thumbnail: "/progress/apr-2023-1.jpg",
            images: [
                "/progress/apr-2023-1.jpg",
                "/progress/apr-2023-2.jpg",
                "/progress/apr-2023-3.jpg"
            ]
        },
        "March 2023": {
            thumbnail: "/progress/mar-2023-1.jpg",
            images: [
                "/progress/mar-2023-1.jpg",
                "/progress/mar-2023-2.jpg",
                "/progress/mar-2023-3.jpg"
            ]
        },
        "February 2023": {
            thumbnail: "/progress/feb-2023-1.jpg",
            images: [
                "/progress/feb-2023-1.jpg",
                "/progress/feb-2023-2.jpg",
                "/progress/feb-2023-3.jpg"
            ]
        },
        "January 2023": {
            thumbnail: "/progress/jan-2023-1.jpg",
            images: [
                "/progress/jan-2023-1.jpg",
                "/progress/jan-2023-2.jpg",
                "/progress/jan-2023-3.jpg"
            ]
        },
        "December 2022": {
            thumbnail: "/progress/dec-1.jpg",
            images: [
                "/progress/dec-1.jpg",
                "/progress/dec-2.jpg",
                "/progress/dec-3.jpg"
            ]
        },
        "November 2022": {
            thumbnail: "/progress/nov-1.jpg",
            images: [
                "/progress/nov-1.jpg",
                "/progress/nov-2.jpg",
                "/progress/nov-3.jpg"
            ]
        },
        "October 2022": {
            thumbnail: "/progress/oct-1.jpg",
            images: [
                "/progress/oct-1.jpg",
                "/progress/oct-2.jpg",
                "/progress/oct-3.jpg"
            ]
        },
        "September 2022": {
            thumbnail: "/progress/sep-1.jpg",
            images: [
                "/progress/sep-1.jpg",
                "/progress/sep-2.jpg",
                "/progress/sep-3.jpg"
            ]
        },
        "August 2022": {
            thumbnail: "/progress/aug-3.jpg",
            images: [
                "/progress/aug-1.jpg",
                "/progress/aug-2.jpg",
                "/progress/aug-3.jpg"
            ]
        },
        "July 2022": {
            thumbnail: "/progress/july-3.jpg",
            images: [
                "/progress/july-1.jpg",
                "/progress/july-2.jpg",
                "/progress/july-3.jpg"
            ]
        },
        "June 2022": {
            thumbnail: "/progress/june-3.jpg",
            images: [
                "/progress/june-1.jpg",
                "/progress/june-2.jpg",
                "/progress/june-3.jpg"
            ]
        },
        "May 2022": {
            thumbnail: "/progress/may-3.jpg",
            images: [
                "/progress/may-1.jpg",
                "/progress/may-2.jpg",
                "/progress/may-3.jpg"
            ]
        },
        "April 2022": {
            thumbnail: "/hoarding/front-3.jpg",
            images: [
                "/progress/entrance.jpg",
                "/progress/front-top.jpg",
                "/progress/front.jpg",
                "/progress/back.jpg",
                "/hoarding/front-1.jpeg",
                "/hoarding/front-2.jpeg",
                "/hoarding/front-3.jpg",
                "/hoarding/side-1.jpeg",
                "/hoarding/side-2.jpeg",
                "/hoarding/side-3.jpeg",
                "/hoarding/back-1.jpeg",
                "/hoarding/back-2.jpg",
                "/hoarding/back-3.jpeg"
            ]
        }
    };

    // Get sorted months for display (newest first)
    const sortedMonths = Object.keys(monthData);

    const CloseButton = () => (
        <button 
            onClick={() => setModal(null)}
            className="absolute top-0 right-0 flex flex-col justify-center items-center w-16 h-16 ml-auto mr-6 transition-basic z-50">
                <div className="transition-basic transform rotate-45 translate-y-[22px] bg-white w-2/4 h-[2px] mb-5"></div>
                <div className="transition-basic transform -rotate-45 bg-white w-2/4 h-[2px]"></div>
        </button>
    );

    return (
        <>
            <div className="container mx-auto pt-10">
                <h3 className="text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-5">
                    Current  
                    <span className="text-primary block italic font-normal">Site Progress</span>
                </h3>
            </div>

            <Swiper
                className="swiper-interior mb-10"
                modules={[Navigation, Autoplay, FreeMode]}
                navigation={true}
                spaceBetween={16}
                slidesPerView={1.75}
                centeredSlides
                initialSlide={2}
                breakpoints={{
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 32,
                        centeredSlides: false,
                        initialSlide: 0
                    },
                }}
            >
                {sortedMonths.map((month, i) => (
                    <SwiperSlide key={`month-${i}`}>
                        <div className="aspect-[4/3] bg-gray-200 relative md:hover:scale-[1.075] transition-basic md:my-5">
                            <button 
                                className="absolute z-50 w-full h-full" 
                                onClick={() => setModal(month)}
                                aria-label={`View ${month} images`}
                            >
                                {' '}
                            </button>
                            <img 
                                src={monthData[month].thumbnail} 
                                alt={`${month} thumbnail`}
                                className="h-full w-full object-cover"
                            />
                            <div className="p-3 mb-2 bg-white text-dark text-center">
                                <span className="font-normal">{month}</span>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={`modal ${modal ? 'visible opacity-100' : 'invisible opacity-0'} transition-basic`}>
                <CloseButton />
                <div className="bg-black bg-opacity-75 p-3 w-full h-full flex items-center justify-center">
                    <button 
                        onClick={() => setModal(null)} 
                        className="absolute top-0 right-0 bottom-0 left-0 -z-1 cursor-default" 
                        aria-label="Close modal"
                    > </button>
                    <div className="w-11/12 lg:w-[100vh] mx-auto relative z-50">
                        {modal && (
                            <Swiper
                                pagination={{ type: "fraction" }}
                                loop
                                className="swiper-interior mb-10 text-white"
                                modules={[Navigation, Pagination]}
                                navigation={true}
                                spaceBetween={16}
                                slidesPerView={1}
                                centeredSlides
                                initialSlide={0}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 16,
                                        centeredSlides: false,
                                        initialSlide: 0
                                    },
                                }}
                            >
                                {monthData[modal].images.map((imagePath, i) => (
                                    <SwiperSlide key={`slide-${i}`}>
                                        <div className="aspect-video mb-10 mt-10">
                                            <img 
                                                src={imagePath} 
                                                alt={`${modal} progress image ${i+1}`}
                                                className="h-full w-full object-cover"
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </div>    
            </div>
        </>
    );
};

export default Progress;